import React from 'react';

import { Container } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Eguna } from '../Eguna';

import ImageGalleryTemplate from '@plone/volto/components/manage/Blocks/Listing/ImageGallery';
import { RenderMendizaleakList } from '../utils';

export const IgoeraView = (props) => {
  const { content } = props;

  const userData = useSelector((state) => state.userdata.userData);

  return (
    <Container>
      <h1 className="documentFirstHeading">{content.parent.title}</h1>

      {content.egindako_eguna ? (
        <p>
          <strong>Igoera eguna</strong>: <Eguna date={content.egindako_eguna} />
        </p>
      ) : (
        <p>
          <strong>Erreserbatutako eguna</strong>: <Eguna date={content.eguna} />
        </p>
      )}

      {content.mendizaleak ? (
        <p>
          <strong>Mendizaleak</strong>
          <RenderMendizaleakList mendizaleak={content.mendizaleak} />
        </p>
      ) : (
        <p>
          <strong>Erreserba egin zuena</strong>: {userData[content.creators[0]]}
        </p>
      )}

      {content.oharrak && (
        <p>
          <strong>Oharrak:</strong> {content.oharrak}
        </p>
      )}

      {content.items && <ImageGalleryTemplate items={content.items} />}
    </Container>
  );
};
